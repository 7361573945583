import * as React from "react";
import { forwardRef } from "react";

const AnimatedPlus = ({ size = 18, ...props }, ref) => {
  const strokeWidth = 2;
  const halfHeight = size / 2;
  const halfStrokeWidth = strokeWidth / 2;

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      ref={ref}
      {...props}
    >
      {/* Horizontal line */}
      <rect
        x="0"
        y={halfHeight - halfStrokeWidth}
        width={size}
        height={strokeWidth}
        rx="1"
        className="transform origin-center transition duration-200 ease-out group-data-[state=open]:!rotate-180"
      />

      {/* Vertical line */}
      <rect
        x={halfHeight - halfStrokeWidth}
        y="0"
        width={strokeWidth}
        height={size}
        rx="1"
        className="transform origin-center transition duration-200 ease-out group-data-[state=open]:rotate-90"
      />
    </svg>
  );
};

export default forwardRef(AnimatedPlus);
