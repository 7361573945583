import React, { useEffect } from "react";
import { Element } from "react-scroll";
import "./index.css";
import { Startpage } from "./components/Startpage";
import { SchedulePage } from "./components/Schedule";
import { RSVP } from "./components/RSVP";
import { InfoPage } from "./components/InfoPage";
import image from "./assets/us.png";

function App() {
  useEffect(() => {
    const parallax = (e) => {
      document.querySelectorAll(".parallax").forEach(function (move) {
        var moving_value = -2;
        var x = (e.clientX * moving_value) / 250;
        var y = (e.clientY * moving_value) / 250;

        move.style.transform =
          "translateX(" + x + "px) translateY(" + y + "px)";
      });
    };
    document.addEventListener("mousemove", parallax);
    return () => {
      document.removeEventListener("mousemove", parallax);
    };
  }, []);

  return (
    <div className="flex flex-col items-center">
      <Element name="startpage">
        <Startpage />
      </Element>
      <div className="flex flex-col gap-14 lg:gap-32 items-center">
        <Element name="schedule">
          <SchedulePage />
        </Element>
        <Element name="image">
          <img
            data-aos="fade-up"
            className="rounded-2xl p-2 parallax floating lg:w-[70dvw] aspect-[2/3] lg:aspect-[3/2] object-cover object-top"
            src={image}
            alt="image of agnes and martin"
          />
        </Element>
        <Element name="info">
          <InfoPage />
        </Element>
        <Element name="rsvp">
          <RSVP />
        </Element>
        <div className="text-[3rem] uppercase pb-10 font-bold text-center">
          Puss & kram!
        </div>
      </div>
    </div>
  );
}

export default App;
