import React from "react";
import { ScheduleDetails } from "../assets/scheduleDetails";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import AnimatedPlus from "../icons/AnimatedPlus";

const Schedule = ({ entries }) => {
  return (
    <Accordion.Root
      className="relative flex flex-col w-full"
      type="multiple"
      collapsible
    >
      {entries.map((entry, index) => (
        <Accordion.Item
          key={index}
          className="px-4 py-6 border border-2 border-green rounded-2xl mb-2"
          value={`schedule-item-${index}`}
          data-aos="fade-up"
        >
          <AccordionTrigger>{entry.day}</AccordionTrigger>
          <AccordionContent>
            <div className="grid grid-cols-[100px_auto] lg:grid-cols-[100px_auto] font-[Garamond] border-green text-xs lg:text-sm">
              {entry.activities.map((activity, idx) => (
                <React.Fragment key={idx}>
                  <div className="border-greenpy-1 text-base">
                    {activity.time}
                  </div>
                  <div
                    className="border-green px-2 pb-1 [&>a]:underline [&>a]:underline-offset-2 text-base"
                    dangerouslySetInnerHTML={{ __html: activity.description }}
                  ></div>
                </React.Fragment>
              ))}
            </div>
          </AccordionContent>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
};

const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Accordion.Header className="flex flex-row justify-between">
        <Accordion.Trigger
          className="text-lg font-[garamond] font-bold flex flex-row items-center justify-between w-full group font-garamond uppercase cursor-pointer"
          {...props}
          ref={forwardedRef}
        >
          {children}
          <AnimatedPlus className="fill-green" aria-hidden />
        </Accordion.Trigger>
      </Accordion.Header>
    );
  }
);

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="font-[garamond] font-bold mt-2 text-base">{children}</div>
    </Accordion.Content>
  )
);

export default Schedule;

export const SchedulePage = () => {
  return (
    <div className="px-2 font-bold w-[100dvw] lg:w-[70dvw]">
      <h2
        data-aos="fade-up"
        className="text-[3rem] text-center font-bold uppercase mb-2"
      >
        Schema
      </h2>
      <Schedule entries={ScheduleDetails} />
    </div>
  );
};
