import React from "react";

export const RSVP = () => {
  return (
    <div className="flex flex-col justify-center p-2 lg:w-[70dvw]">
      <h1
        data-aos="fade-up"
        className="text-[3rem] uppercase text-center leading-none font-bold mb-2"
      >
        Vi ses snart!
      </h1>
      <h3
        data-aos="fade-up"
        className="mb-4 text-base font-[garamond] font-bold text-center"
      >
        OSA har stängt. Skulle du mot alla odds behöva ställa in, eller vill
        uppdatera matpreferenser, kan du kontakta Agnes eller Martin.
      </h3>
    </div>
  );
};
