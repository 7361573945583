import React from "react";
import { EventDetails } from "../assets/eventDetails";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import AnimatedPlus from "../icons/AnimatedPlus";

export const InfoPage = () => {
  return (
    <div className="p-2 lg:p-0 w-[100dvw] lg:w-[70dvw]">
      <h2
        data-aos="fade-up"
        className="text-[3rem] font-bold uppercase mb-2 text-center"
      >
        Detaljer
      </h2>
      <Accordion.Root className="w-full" type="multiple" collapsible>
        {EventDetails.map((event, index) => (
          <Accordion.Item
            key={index}
            className="px-4 py-6 border border-2 border-green rounded-2xl mb-2"
            value={`item-${index}`}
            data-aos="fade-up"
          >
            <AccordionTrigger>{event.title}</AccordionTrigger>
            <AccordionContent>{event.content}</AccordionContent>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </div>
  );
};

const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Accordion.Header className="flex flex-row justify-between">
        <Accordion.Trigger
          className="text-lg font-[garamond] font-bold flex flex-row items-center justify-between w-full group font-garamond uppercase cursor-pointer"
          {...props}
          ref={forwardedRef}
        >
          {children}
          <AnimatedPlus className="fill-green" aria-hidden />
        </Accordion.Trigger>
      </Accordion.Header>
    );
  }
);

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="font-[garamond] font-bold mt-2 text-base">{children}</div>
    </Accordion.Content>
  )
);

export default InfoPage;
